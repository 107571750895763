@mixin block--center {
    margin: 0 auto;
 	text-align:center;
 	display:block;
}
@mixin no-space{
    margin:0;
    padding:0;
}
@mixin font-face($style-name, $file, $category:"") {
    $filepath: "../static/fonts/" + $file;
    @font-face {
        font-family: "#{$style-name}";
        src: url($filepath + ".eot");
        src: url($filepath + ".eot?#iefix") format('embedded-opentype'), url($filepath + ".woff") format('woff'), url($filepath + ".ttf")  format('truetype'), url($filepath + ".svg#" + $style-name + "") format('svg');
    }
    %#{$style-name} {
        font: {
            @if $category != "" {
                family: "#{$style-name}", #{$category};
            }
            @else {
                family: "#{$style-name}";
                weight: normal;
            }
        }
    }
}
@mixin alpha-background-color($color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);
    background-color: $solid-color;
    background-color: $color;
}

@mixin stroke($color, $size: 1) {
  $value: $color;

  @for $i from $size * -1 through $size {
    @for $j from $size * -1 through $size {
      @if not ($i == 0px and $j == 0px) {
        $value: $value, $i $j $color;
      }
    }
  }

  text-shadow: $value;
}
//oong use gen animation code
@mixin keyframes ($an) {
    @-webkit-keyframes #{$an} { @content; }
    @-moz-keyframes #{$an} { @content; }
    @-o-keyframes #{$an} { @content; }
    @keyframes #{$an} { @content;  }
}
@mixin animation ($delay, $duration, $animation, $direction: forward, $fillmode: fowards) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-fill-mode: $fillmode;
    -webkit-animation-direction: $direction;

    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-fill-mode: $fillmode;
    -moz-animation-direction: $direction;

    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-fill-mode: $fillmode;
    animation-direction: $direction;
}
@import 'mixins.scss';
// @include font-face('PSLEmpirePro', 'PSLEmpirePro', 'serif');
@include font-face('PSLDisplayPro', 'PSLDisplayPro', 'serif');
@include font-face('PSLKandaModernProBold', 'PSLKandaModernProBold', 'serif');
@include font-face('PSLKandaModernExtraPro', 'PSLKandaModernExtraPro', 'serif');

html,body{height: 100%;}
//for newer iphone
html {
    @media only screen
    and (device-width : 812px)
    and (device-height : 375px)
    and (-webkit-device-pixel-ratio : 3) { padding: env(safe-area-inset); }
    @media only screen
    and (device-width : 896px)
    and (device-height : 414px)
    and (-webkit-device-pixel-ratio : 3) { padding: env(safe-area-inset); }
}

body {
    padding: 0px;
    margin: 0px;
    min-width: 320px;
    font-family: 'PSLDisplayPro';
    font-size:16px;
    background-color: #88b9da;
    @media(max-width: 650px){
            font-size:14px;
    }
    @media(min-width: 1024px){
        font-size:22px;
    }
    @media(min-width: 1366px){
        font-size:24px;
    }
    @media(min-width: 1800px){
        font-size:26px;
    }
}
* {
    //user-select: none;
    box-sizing: border-box;
}

a{
	&, &:hover, &:active, &:focus{
        cursor: pointer;
        text-decoration: none;
        // color:#ffffff;
	}
}

img{
    max-width: 100%;
    max-height: 100%;
}

.disable{
    pointer-events: none;
    filter:grayscale(1) brightness(0.6);
}
